
.my-approval-layout {
	.filter-box {
		padding: 30px 0 0 0;
	}
}
.line-split {
	height: 3px;
	margin: 15px auto;
	border-bottom: 3px dashed #eee;
}
