
.info-layout {
    height: 100%;
    // position: relative;

    .info-header {
        position: absolute;
        top: 0;
        left: 0;
        height: 40px;
    }

    .info-content {
        height: 100%;
        overflow-y: auto;
        padding: 0 10px;
    }

    .file-name {
        cursor: pointer;
        color: #60adf9;
        text-decoration: underline;
    }

    .view-form {
		/deep/ .el-form-item__label {
			padding: 9px !important;
			text-align: right;
			background-color: #f5f5f5;
			border: 1px solid #eee !important;
		}
		/deep/ .el-form-item__content {
			height: 48px !important;
			border: 1px solid #eee !important;
			display: flex;
			align-items: center;
			padding-left: 15px;
		}
        
        .sp {
			display: flex;
            .el-col-4,
            .el-col-20 {
                padding: 10px 15px !important;
            }
            .el-col-4 {
                width: 260px;
                text-align: right;
                background-color: #f7f7f7;
                border: 1px solid #eee;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                color: #606266;
                font-size: 14px;
            }
            .el-col-20 {
                width: calc(~'100% - 260px');
                border: 1px solid #eee;
                color: #999;
                font-size: 14px;
                line-height: 24px;
            }
        }
	}

    .right-btns {
		position: absolute;
		right: 0;
		top: 66px;
		padding: 0 10px;
		background-color: #fff;
		z-index: 10;
	}
}
.btn {
    padding: 0 50px !important;
}
.info-card-content {
    /deep/ .el-form-item {
        margin-bottom: 0px;
        color: #999;
    }

    // .cutting-line {
    //     border: 1px dashed #ddd;
    //     margin: 5px 50px;
    // }

    .btnGroups {
        line-height: 30px;
        margin-bottom: 10px;
    }

    .pd-table {
        padding: 0 20px 20px;
    }
}
.tip-box {
	line-height: 25px;
	background: #ecf5ff;
	border-radius: 5px;
	border: 1px solid #d9ecff;
	color: #419eff;
	padding: 5px 15px;
	.left-indent {
		text-indent: 3.5rem;
	}
}
