.info-layout {
  height: 100%;
}
.info-layout .info-header {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
}
.info-layout .info-content {
  height: 100%;
  overflow-y: auto;
  padding: 0 10px;
}
.info-layout .file-name {
  cursor: pointer;
  color: #60adf9;
  text-decoration: underline;
}
.info-layout .view-form /deep/ .el-form-item__label {
  padding: 9px !important;
  text-align: right;
  background-color: #f5f5f5;
  border: 1px solid #eee !important;
}
.info-layout .view-form /deep/ .el-form-item__content {
  height: 48px !important;
  border: 1px solid #eee !important;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.info-layout .view-form .sp {
  display: flex;
}
.info-layout .view-form .sp .el-col-4,
.info-layout .view-form .sp .el-col-20 {
  padding: 10px 15px !important;
}
.info-layout .view-form .sp .el-col-4 {
  width: 260px;
  text-align: right;
  background-color: #f7f7f7;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #606266;
  font-size: 14px;
}
.info-layout .view-form .sp .el-col-20 {
  width: calc(100% - 260px);
  border: 1px solid #eee;
  color: #999;
  font-size: 14px;
  line-height: 24px;
}
.info-layout .right-btns {
  position: absolute;
  right: 0;
  top: 66px;
  padding: 0 10px;
  background-color: #fff;
  z-index: 10;
}
.btn {
  padding: 0 50px !important;
}
.info-card-content /deep/ .el-form-item {
  margin-bottom: 0px;
  color: #999;
}
.info-card-content .btnGroups {
  line-height: 30px;
  margin-bottom: 10px;
}
.info-card-content .pd-table {
  padding: 0 20px 20px;
}
.tip-box {
  line-height: 25px;
  background: #ecf5ff;
  border-radius: 5px;
  border: 1px solid #d9ecff;
  color: #419eff;
  padding: 5px 15px;
}
.tip-box .left-indent {
  text-indent: 3.5rem;
}
