
.audit-msg-layout {
	padding: 0px 0;
	height: 100%;
	text-align: right;
	position: relative;
	.btn1 {
		margin: 5px;
	}
	.overflow-y {
		height: 100% - 5px;
		overflow-y: auto;
	}
	.msg-head {
		background-color: #496dd6;
		padding-left: 20px;
		height: 34px;
		line-height: 34px;
		margin-top: 15px;
		font-size: 14px;
		color: #fff;
	}
	.msg-content {
		/deep/ .el-collapse-item {
			margin-bottom: 12px;
		}
		/deep/ .el-collapse-item__header {
			padding-left: 20px;
			background: #e4e7ed;
			color: #202020;
			height: 38px;
			line-height: 38px;
			font-weight: bolder;
		}
		/deep/.el-collapse-item__wrap {
			border-bottom: none;
		}
		/deep/ .el-collapse-item__content {
			padding-bottom: 0;
		}
		.no-have {
			text-align: center;
			padding: 20px 0;
			border: 1px solid #e5e5e5;
			border-top: none;
			color: #666666;
		}
		.el-form-item {
			margin-bottom: 0;
		}
		/deep/ .el-form-item__label {
			background: #f2f6fc;
			word-break: break-all;
			padding: 0 10px;
			height: 38px;
			line-height: 38px;
			border: 1px solid #dcdcdc;
			border-top: none;
			font-size: 12px;
			color: #666666;
		}
		/deep/ .el-form-item__content {
			padding-left: 15px;
			border-bottom: 1px solid #e5e5e5;
			border-right: 1px solid #e5e5e5;
			height: 38px;
			line-height: 38px;
		}
	}
	.even-border {
		/deep/ .el-form-item__label {
			border-left: none;
		}
	}
	.long-label {
		/deep/ .el-form-item__label {
			width: 225px !important;
		}
	}
	.special-item {
		/deep/ .el-form-item__label {
			height: 150px;
			line-height: 150px;
		}
		/deep/ .el-form-item__content {
			height: 150px;
			line-height: 30px;
		}
	}
	.footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 10px 0;
		background-color: #fff;
		box-shadow: 0px 0px 30px 0px rgba(201, 201, 201, 0.5);
		z-index: 3;
	}
	.red {
		color: red;
		font-weight: bolder;
	}
	.text-overflow-5 {
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
	}
}
